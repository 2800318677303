h2 {
    margin-bottom: 30px !important;
}


@keyframes liveEnter {
    from {
        background-color: rgba(0, 255, 0, 0.3);
    }
    to {
    }
}

.lineItem {
    animation-name: liveEnter;
    animation-duration: 200ms;
}

.inspectionModal {
    max-width:80vw !important;
    max-height: 60vh !important;
    padding: 10px 50px;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.INFO {
}

.WARNING {
    background: rgba(255, 255, 0, 0.1);
}

.ERROR {
    background: rgba(255, 0, 0, 0.1);
}


.compare {
    position: fixed;
    top: 50px;
    right: 20px;
    z-index: 10000000;
    opacity: 0.8;
    padding: 10px 10px;
    background-color: rgb(200,200,200);
}

tbody tr {
    cursor: pointer;
}

tbody tr:hover {
    background-color: #eee;
}

.stackTrace {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stackTrace-INFO {
    background: rgba(0, 0, 0, 0.1);
}

.stackTrace-WARNING {
    background: rgba(255, 255, 0, 0.1);
}

.stackTrace-ERROR {
    background: rgba(255, 0, 0, 0.1);
}