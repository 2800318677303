.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  font-size: 10px;
  position: relative;
  border-top: 1px inset rgba(1, 28, 44, 0.2);
  border-right: 1px inset rgba(1, 28, 44, 0.2);
  border-bottom: 1px inset rgba(1, 28, 44, 0.2);
  border-left: 1px inset rgba(1, 28, 44, 1);
  filter: drop-shadow(0px 0px 10px rgba(1, 28, 44, 1));
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader-spin 1s infinite alternate-reverse ease-in-out;
  animation: loader-spin 1s infinite alternate-reverse ease-in-out;
}

.loader-absolute {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 99999999;
  filter: drop-shadow(0px 0px 10px rgba(1, 28, 44, 1));
}

.loaderImg {
    -webkit-animation: loader-spin-reverse 1s infinite alternate-reverse ease-in-out;
    animation: loader-spin-reverse 1s infinite alternate-reverse ease-in-out;
}


@-webkit-keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-spin-reverse {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes loader-spin-reverse {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
