.serviceConfigModalFormWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
}
.serviceConfigModalFormWrapper > :not(:last-child) {
    margin-bottom: 20px;
}

.multiInput {
    display: grid;
    grid-template-columns: 90% 10%;
}

.modalSwitch {
    margin-left: 20px;
}

.configModalHeading {
    width: 50vw;
}