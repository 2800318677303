.logInForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 20px;
}

.logInForm > :not(:last-child) {
    margin-bottom: 20px;
}