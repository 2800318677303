
.modalOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.3);

    color: rgb(1, 28, 44);
}

.btn-primary {
    background-color: rgb(1, 28, 44) !important;
}

.deployInfo {
    position: fixed;
    top:0;
    right: 0;
    background-color: rgba(0,0,0, 0.2);
    color: rgba(0,0,0, 0.8);
    padding: 2.5px 5px;
    border-bottom-left-radius: 10px;

}

.appBarLogo {
    height: 64px;
    margin-right: 30px;
}

a {
    color: #4492c6;
}
a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
}

section {
    display: flex;
    width: 90%;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
}
section >*:not(:last-child) {
    margin-bottom: 20px;
}