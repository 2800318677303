body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #EEE;
}


@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"), url("./fonts/ProximaNova-Light.ttf") format("truetype");
}

* {
  font-family: 'ProximaNova' !important;
}